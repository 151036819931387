import { Suspense, lazy } from 'react';

const Sending = lazy(() => import('./Sending'));

export const SendingLazy = () => {
  return (
    <Suspense>
      <Sending />
    </Suspense>
  );
};
