import axios, { AxiosError } from 'axios';

import { routing } from '@/configs';

export function setupUploadAPIClient() {
  const access_token = localStorage.getItem('at');

  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api`,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': 'multipart/form-data',
    },
  });

  instance.interceptors.response.use(
    (response: any) => response,
    (error: AxiosError<{ message: string }>) => {
      if (error.response?.status === 401) {
        localStorage.removeItem('at');
        window.location.href = routing.signin;
      }
    }
  );

  return instance;
}

export const uploadsApi = setupUploadAPIClient();
