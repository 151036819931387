import { Suspense, lazy } from 'react';

const AutoSending = lazy(() => import('./AutoSending'));

export const AutoSendingLazy = () => {
  return (
    <Suspense>
      <AutoSending />
    </Suspense>
  );
};
