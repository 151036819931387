import { Suspense, lazy } from 'react';

const Groups = lazy(() => import('./Groups'));

export const GroupsLazy = () => {
  return (
    <Suspense>
      <Groups />
    </Suspense>
  );
};
