import { createApi } from '@reduxjs/toolkit/dist/query/react';
import customFetch from './customFetch';
import {
  AutoSendingData,
  CreateAutoSendingData,
  UpdateAutoSendingData,
} from '@/types';

export const autoSendingAPI = createApi({
  reducerPath: 'autoSendingAPI',
  baseQuery: customFetch,

  tagTypes: ['AutoSending'],
  endpoints: (builder) => ({
    getAutoSendingsInGroup: builder.query<
      AutoSendingData[],
      { group_id: number | undefined }
    >({
      query: ({ group_id }) => ({
        url: '/auto-sending',
        method: 'GET',
        params: { group_id },
      }),
      providesTags: () => ['AutoSending'],
    }),
    createAutoSending: builder.mutation<AutoSendingData, CreateAutoSendingData>(
      {
        query: (body) => ({
          url: '/auto-sending',
          method: 'POST',
          body,
        }),
        invalidatesTags: () => ['AutoSending'],
      },
    ),
    updateAutoSending: builder.mutation<
      { status: string },
      UpdateAutoSendingData
    >({
      query: (body) => ({
        url: '/auto-sending',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['AutoSending'],
    }),

    deleteAutoSending: builder.mutation<{ result: boolean }, { id: number }>({
      query: ({ id }) => ({
        url: `/auto-sending/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AutoSending'],
    }),
  }),
});

export const {
  useGetAutoSendingsInGroupQuery,
  useCreateAutoSendingMutation,
  useDeleteAutoSendingMutation,
  useUpdateAutoSendingMutation,
} = autoSendingAPI;
