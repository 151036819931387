import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthProvider';
import { routing } from '@/configs';
import { PageLayout } from '@/layouts/PageLayout';
import {
  AutoSendingLazy,
  Dashboard,
  GroupsLazy,
  SendingLazy,
  Signin,
} from '@/pages';

export const ReactRouterProvider = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routing.dashboard} element={<AuthProvider />}>
          <Route path="" element={<PageLayout />}>
            <Route index element={<Dashboard />} />
            <Route path={routing.sending} element={<SendingLazy />} />
            <Route path={routing.groups} element={<GroupsLazy />} />
            <Route
              path={`${routing.auto_sending}/:group_id`}
              element={<AutoSendingLazy />}
            />
          </Route>
        </Route>
        <Route path={routing.signin} element={<Signin />}></Route>
      </Routes>
    </BrowserRouter>
  );
};
