import { SignInCredentials } from '@/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const authAPI = createApi({
  reducerPath: 'authAPI',

  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api/auth`,
    prepareHeaders: (headers) => {
      const access_token = localStorage.getItem('at');
      if (access_token) headers.set('authorization', `Bearer ${access_token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    signin: builder.mutation<{ token: string }, SignInCredentials>({
      query: (body) => ({
        url: '/signin',
        method: 'POST',
        body,
      }),
    }),
    checkAuth: builder.query<{ auth: boolean }, void>({
      query: () => ({
        url: '/',
        method: 'GET',
      }),
    }),
  }),
});

export const { useSigninMutation, useCheckAuthQuery } = authAPI;
