import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { toggleSendingStatus } from '../features';
import customFetch from './customFetch';

export const botAPI = createApi({
  reducerPath: 'botAPI',
  baseQuery: customFetch,

  endpoints: (builder) => ({
    getBotStatus: builder.query<{ sending_active: boolean }, void>({
      query: () => ({
        url: '/bot/status',
        method: 'GET',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(toggleSendingStatus(data.sending_active));
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetBotStatusQuery } = botAPI;
