import { createApi } from '@reduxjs/toolkit/dist/query/react';
import customFetch from './customFetch';
import { Group, GroupWithUsersCount } from '@/types';

export const groupsAPI = createApi({
  reducerPath: 'groupsAPI',
  baseQuery: customFetch,

  tagTypes: ['Groups', 'Group'],
  endpoints: (builder) => ({
    getGroups: builder.query<GroupWithUsersCount[], void>({
      query: () => ({
        url: '/groups/',
        method: 'GET',
      }),
      providesTags: () => ['Groups'],
    }),
    getGroup: builder.query<Group, { id: number | undefined }>({
      query: ({ id }) => ({
        url: `/groups/${id}`,
        method: 'GET',
      }),
      providesTags: () => ['Group'],
    }),

    addGroup: builder.mutation<Group, { name: string }>({
      query: (body) => ({
        url: '/groups/',
        method: 'POST',
        body,
      }),
      invalidatesTags: () => ['Groups'],
    }),
    renameGroup: builder.mutation<Group, Pick<Group, 'id' | 'name'>>({
      query: (body) => ({
        url: '/groups/',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: () => ['Groups'],
    }),
    deleteGroup: builder.mutation<{ result: boolean }, { id: number }>({
      query: ({ id }) => ({
        url: `/groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Groups'],
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useGetGroupQuery,
  useAddGroupMutation,
  useDeleteGroupMutation,
  useRenameGroupMutation,
} = groupsAPI;
